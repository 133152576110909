<template>
  <b-container fluid>
    <StrapiMarkdownComponent v-if="item" :item="item.attributes.contenu">
    </StrapiMarkdownComponent>
  </b-container>
</template>

<script>
import StrapiMarkdownComponent from "../../components/strapi/StrapiMarkdownComponent.vue";
import StrapiService from "../../services/strapi.service";
export default {
  name: "StrapiEcoconceptionView",
  components: { StrapiMarkdownComponent },
  data: () => ({
    item: null,
  }),
  mounted() {
    StrapiService.getEcoconception().then(
      (response) => (this.item = response.data.data)
    );
  },
};
</script>
